// routeConstants.js
'use strict';

export const globalAllowedSearchParams = [
  'campaign',
  'campaign-id',
  'region',
  'action',
  'act',
  'login-email',
  'email',
  'order',
  'p_id',
];

export const allowedSearchParams = {
  '/': ['feed', 'filters', 'sorting', 'sorts'],
  '/search/all': ['q'],
  '/search/:type': ['q', 'sorting', 'sorts'],
  '/user-following-grid/:category': ['ui'],
  '/user-feed-grid/:feed': ['sorting', 'sorts', 'ssr', 'ui'],
  '/flix-categories/:category': ['page', 'sorting', 'sorts', 'ssr', 'ui'],
  '/story-categories/:category': ['page', 'sorting', 'sorts', 'ssr', 'ui'],
  '/hashtag/:hashtag/:type': ['sorting', 'sorts'],
  '/category/:category/:type': ['sorting', 'sorts', 'ssr'],
  '/user/:userId/:type': ['sorting', 'sorts'],
};
