// jsBridgeInterface.js
'use client';
'use strict';

import { resourceUrlConfigMaps } from '../action/updateConfigurations.js';
import { getIsXUserAgent } from '../resource/getUserAgent.js';

const isOnWebview = getIsXUserAgent();

if (isOnWebview) {
  if (!window.__JS_BRIDGE_READY_PROMISE__) {
    window.__JS_BRIDGE_READY_PROMISE__ = new Promise(resolve => {
      window.__JS_BRIDGE_READY_RESOLVE__ = resolve;
    });
  }

  let resolve;
  const promise = new Promise(r => {
    resolve = r;
  });

  // use property descriptor to make the promise and resolve immutable
  Object.defineProperty(window, '__WEBVIEW_READY_PROMISE__', {
    value: promise,
    writable: false,
    configurable: false,
  });

  Object.defineProperty(window, '__WEBVIEW_READY_RESOLVE__', {
    value: resolve,
    writable: false,
    configurable: false,
  });

  const getRefreshToken = async () => {
    try {
      const meData = await window.getDbData?.('me');
      return meData?.refreshToken ?? null;
    } catch (_) {
      //
    }
    return null;
  };

  const setResourceUrl = resourceUrl => {
    window.__RESOURCE_URL__ = resourceUrl;
    window.__WEBVIEW_READY_RESOLVE__();
  };

  const setupWebAppFunctions = () => {
    const functions = {
      getRefreshToken,
      setResourceUrl,
    };

    Object.entries(functions).forEach(([name, func]) => {
      if (!window.WebApp[name]) {
        window.WebApp[name] = func;
      }
    });
  };

  if (window.WebApp) {
    setupWebAppFunctions();

    if (window.WebApp.remoteConfig) {
      const resourceUrl = {};
      Object.keys(resourceUrlConfigMaps).forEach(key => {
        if (window.WebApp.remoteConfig[key]) {
          resourceUrl[resourceUrlConfigMaps[key]] =
            window.WebApp.remoteConfig[key];
        }
      });

      if (Object.keys(resourceUrl).length) {
        window.__RESOURCE_URL__ = resourceUrl;
      }
    }

    window.__WEBVIEW_READY_RESOLVE__();
  } else {
    window.addEventListener('message', event => {
      if (event.data === 'webAppReady' && window.WebApp) {
        setupWebAppFunctions();
      }
    });
  }

  window.__JS_BRIDGE_READY_RESOLVE__();
}

export const hasWebviewCallHandler = () => {
  return isOnWebview && window.WebApp?.callHandler;
};

export const callWebviewHandler = (name, ...args) => {
  if (hasWebviewCallHandler()) {
    window.WebApp.callHandler(name, ...args);
  }
};
