// updateConfigurations.js
'use strict';
import fetchRemoteConfig from '../action/fetchRemoteConfig.js';
import getOperationData from '../selector/getOperationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getConfigurations from '../selector/getConfigurations.js';
import getAbTestingData from '../selector/getAbTestingData.js';
import {
  MERGE_OPERATION_DATA,
  MERGE_REMOTE_CONFIG_DATA,
} from '../ActionTypes.js';
import { setRemoteMeta } from '../resource/mixpanel.js';
import { setAppBundleIds } from '../resource/getUserAgent.js';
import {
  API,
  WATCH,
  PUBLIC,
  RSS_FEED,
} from '../resource/resourceUrlTypeConstants.js';
import { defaultPublicUrlPathMap } from '../resource/publicUrlPathConstants.js';
import {
  APP_BUNDLE_IDS,
  API_URL_PREFIX,
  WATCH_URL_PREFIX,
  PUBLIC_URL_PREFIX,
  RSS_FEED_URL_PREFIX,
  SSR_API_URL_PREFIX,
} from '../RemoteConfigKeys.js';
import { updateEnv } from '../resource/env.js';
import ServiceWorkerMessenger from '../resource/ServiceWorkerMessenger.js';
import { getIsXUserAgent } from '../resource/getUserAgent.js';
import { SET_OPERATION_DATA } from '../ActionTypes.js';
const ONE_WEEK_SECS = 7 * 24 * 60 * 60;
const isServer = typeof window === 'undefined';
const isWebview = getIsXUserAgent();

export const resourceUrlConfigMaps = {
  [API_URL_PREFIX]: API,
  [WATCH_URL_PREFIX]: WATCH,
  [PUBLIC_URL_PREFIX]: PUBLIC,
  [RSS_FEED_URL_PREFIX]: RSS_FEED,
};

/**
 * Get unique configIds
 * @param {object} options - options object.
 * @param {array} options.configIds - configIds array.
 * @returns {array} unique configIds array.
 */
export const getConfigIds = ({ configIds = [] } = {}) => {
  return [...new Set([...configIds].reverse())].reverse();
};

const getUrlConfigs = ({ remoteConfig }) => {
  const resourceUrl = {};
  Object.keys(resourceUrlConfigMaps).forEach(key => {
    resourceUrl[resourceUrlConfigMaps[key]] = remoteConfig[key];
  });
  if (isServer) {
    resourceUrl[API] = remoteConfig[SSR_API_URL_PREFIX] || resourceUrl[API];
  }

  const publicUrlPath = { ...defaultPublicUrlPathMap };
  Object.keys(defaultPublicUrlPathMap).forEach(key => {
    publicUrlPath[key] = remoteConfig[key] || publicUrlPath[key];
  });
  return { resourceUrl, publicUrlPath };
};

/**
 * Update configurations
 * @kind action
 * @param {string} {config} - target config key, example: global, naeu.
 * @return {Promise} Action promise.
 */
const updateConfigurations =
  ({ configKey }) =>
  async (dispatch, getState) => {
    const selectPath = ['config'];

    let configurationGroups =
      getOperationData(getState(), selectPath, 'configurationGroups') || {};
    const defaultConfigKey = getOperationData(
      getState(),
      selectPath,
      'defaultConfigKey'
    );
    const configurations = getConfigurations(getState()) || [];
    const newConfigKey = configKey || defaultConfigKey;
    const configGroupsPriorityArray = Object.keys(configurationGroups)
      .map(priority => configurationGroups[priority][newConfigKey])
      .filter(priorityData => priorityData);

    const flatAllConfigIds = configGroupsPriorityArray.reduce((prev, curr) => {
      return prev.concat(curr);
    }, []);
    const uniqueAllConfigIds =
      flatAllConfigIds.length > 0
        ? getConfigIds({ configIds: flatAllConfigIds })
        : configurations;

    const fetchedConfigIds = uniqueAllConfigIds.filter(configId => {
      const fetchedData = getOperationData(
        getState(),
        [...selectPath, 'configurationData'],
        configId
      );
      // Check networking isFetched once to avoid `fetchedData` out date.
      // e.g. PWA restored from indexedDB
      const isFetched = getNetworkingData(
        getState(),
        [...selectPath, 'configurations', configId],
        'isFetched'
      );

      if (fetchedData && isFetched) {
        return configId;
      }
      return false;
    });

    const needFetchConfigIds = uniqueAllConfigIds.filter(
      configId => !fetchedConfigIds.includes(configId)
    );

    await Promise.all(
      needFetchConfigIds.map(configId =>
        dispatch(fetchRemoteConfig({ configId }))
      )
    );

    // Avoid use outdated `configurationGroups` to generate remote config.
    configurationGroups =
      getOperationData(getState(), selectPath, 'configurationGroups') || {};

    const configGroupsDataAry = Object.keys(configurationGroups).map(
      priority => {
        const configKeys = configurationGroups[priority][newConfigKey] || [];
        const remoteConfigData = configKeys.reduce(
          (prev, current) =>
            Object.assign(
              prev,
              getOperationData(
                getState(),
                ['config', 'configurationData'],
                current
              )
            ),
          {}
        );
        const abTestData = getAbTestingData(
          getState(),
          undefined,
          'variableObject',
          priority
        );
        return Object.assign({}, remoteConfigData, abTestData);
      }
    );

    let remoteConfigResult = configGroupsDataAry.reduce(
      (prev, current) => Object.assign(prev, current),
      {}
    );

    // always merge remoteConfig from webview
    if (isWebview && window.WebApp?.remoteConfig) {
      try {
        remoteConfigResult = {
          ...remoteConfigResult,
          ...window.WebApp.remoteConfig,
        };

        dispatch({
          type: SET_OPERATION_DATA,
          payload: {
            selectPath: ['config', 'configurationData', 'webview'],
            data: window.WebApp.remoteConfig,
          },
        });

        const domain = location.hostname;
        const apiDomain = new URL(remoteConfigResult[API_URL_PREFIX]).hostname;
        const publicDomain = new URL(remoteConfigResult[PUBLIC_URL_PREFIX])
          .hostname;

        Object.entries(remoteConfigResult).forEach(([key, value]) => {
          remoteConfigResult[key] = value
            .replace(/api\.swag\.live/g, apiDomain)
            .replace(/public\.swag\.live/g, publicDomain)
            .replace(/(?<!\.)(swag\.live)/g, domain);
        });
      } catch (error) {
        //
      }
    }

    updateEnv(remoteConfigResult);

    if (remoteConfigResult[APP_BUNDLE_IDS]) {
      setAppBundleIds({
        ids: remoteConfigResult[APP_BUNDLE_IDS]?.split(' '),
      });
    }

    const { resourceUrl, publicUrlPath } = getUrlConfigs({
      remoteConfig: remoteConfigResult,
    });
    if (!isServer) {
      document.cookie = `region=${newConfigKey};SameSite=Lax;max-age=${ONE_WEEK_SECS}`;
      window.__RESOURCE_URL__ = resourceUrl;
      window.__PUBLIC_URL_PATH__ = publicUrlPath;
    } else {
      globalThis.__RESOURCE_URL__ = resourceUrl;
      globalThis.__PUBLIC_URL_PATH__ = publicUrlPath;
    }

    setRemoteMeta({
      data: {
        'configuration.id': newConfigKey,
      },
    });
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          currentConfigIds: uniqueAllConfigIds,
        },
      },
    });
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          currentConfigKey: newConfigKey,
        },
      },
    });
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['showcase'],
        data: {
          activeIndex: 0,
        },
      },
    });

    if (!isServer) {
      const messenger = ServiceWorkerMessenger.getInstance();
      // not sure why jest throws error of missing messenger
      messenger
        ?.set({
          selectPath: ['remoteConfig', 'data'],
          value: remoteConfigResult,
          options: {
            objectDepth: 2,
            isCritical: true,
          },
        })
        .catch(() => null);
    }

    return dispatch({
      type: MERGE_REMOTE_CONFIG_DATA,
      payload: remoteConfigResult,
    });
  };

export default updateConfigurations;
