// getToObject.js
'use strict';

import { matchPath } from 'react-router-dom';
import {
  globalAllowedSearchParams,
  allowedSearchParams,
} from '../resource/routeConstants.js';

/**
 * @typedef Path
 * @property {string} pathname - A URL pathname, beginning with a /.
 * @property {string} search - A URL search string, beginning with a ?.
 * @property {string} hash - A URL fragment identifier, beginning with a #.
 */

/**
 * Find the allowed search parameters for a given pathname
 * @param {string} pathname - The current pathname
 * @returns {string[]} The allowed search parameters
 */
const findAllowedSearchParams = pathname => {
  return Object.entries(allowedSearchParams).find(([pattern]) => {
    const matches = matchPath(pattern, pathname);
    return matches !== null;
  })?.[1];
};

/**
 * Filter URL parameters and keep only those in the whitelist
 * @param {string} search - URL search string
 * @param {string} pathname - Current pathname
 * @returns {string} Filtered search string
 */
const filterSearch = (pathname = '', search = '') => {
  const filteredParams = new URLSearchParams();
  const allowedParams = findAllowedSearchParams(pathname) || [];

  // Sort params to improve cache-ability
  [...new URLSearchParams(search)].sort().forEach(([key, value]) => {
    if (
      globalAllowedSearchParams.includes(key) ||
      allowedParams.includes(key)
    ) {
      filteredParams.append(key, value);
    }
  });

  return filteredParams.toString() ? `?${filteredParams.toString()}` : '';
};

/**
 * Add language parameter to search string
 * @param {string} search - URL search string
 * @param {string} language - language code
 * @returns {string} Search string with language parameter
 */
const appendLanguageParam = (search, language) => {
  const searchParams = new URLSearchParams(search);
  searchParams.set('lang', language);
  return `?${searchParams.toString()}`;
};

/**
 * Parses a string URL path into its separate pathname, search, and hash components.
 * @param {string} {path} - url path.
 * @return {Path}
 */
export const parsePath = ({ path }) => {
  if (!path) return {};

  try {
    const url = new URL(path, 'https://swag.live');
    const hasPath = path.startsWith('/');

    return {
      ...(url.hash && { hash: url.hash }),
      ...(url.search && { search: url.search }),
      ...(hasPath && url.pathname && { pathname: url.pathname }),
    };
  } catch (_) {
    return {};
  }
};

/**
 * Get to object with filtered search params and language parameter
 * @param {string|Path} {[to = '']} - destination url.
 * @param {string} {[language = 'en']} - language code.
 * @param {boolean} {[shouldFilterSearch = false]} - filter search params.
 * @return {Path}
 */
const getToObject = ({
  to = '',
  language = 'en',
  shouldFilterSearch = false,
} = {}) => {
  const path = typeof to === 'object' ? { ...to } : parsePath({ path: to });

  let search = path.search;

  // Filter parameters first, then add language parameters
  if (shouldFilterSearch) {
    search = filterSearch(path.pathname, path.search);
  }

  const searchWithLang = appendLanguageParam(search, language);

  return {
    ...path,
    search: searchWithLang,
  };
};

export default getToObject;
