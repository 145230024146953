// postMessageToSwagApp.js
'use strict';
import { goBack } from 'redux-first-history';

import {
  getIsOnAndroidWebview,
  getIsOnIOSWebview,
} from '../resource/getUserAgent.js';
import { push } from '../action/navigationAction.js';
import { callWebviewHandler } from '../resource/jsBridgeInterface.js';

/**
 * Post message to SWAG app.
 * @kind action
 * @param {string} {message} - passing message to mobile
 * @param {string} {[fallbackRouteInWebApp]} - when the mobile app API is unavaliable. will fallback to the route
 * @param {Array} {[messageArgs]} - array of additional arguments to pass to the webview handler
 * @return {Promise} Action promise.
 */
const postMessageToSwagApp =
  ({ message, fallbackRouteInWebApp, messageArgs = [] }) =>
  async dispatch => {
    if (!message) return false;

    if (getIsOnAndroidWebview()) {
      callWebviewHandler(message, ...messageArgs);
      if (typeof WebApp !== 'undefined')
        return window?.WebApp?.postMessage?.(message);
    }
    if (getIsOnIOSWebview()) {
      callWebviewHandler(message, ...messageArgs);
      return window?.webkit?.messageHandlers?.WebApp?.postMessage?.(message);
    }

    if ('closeWebView' === message) {
      if ('home' === fallbackRouteInWebApp) {
        return dispatch(push('/'));
      } else {
        return dispatch(goBack());
      }
    }

    if (fallbackRouteInWebApp) {
      return dispatch(push(fallbackRouteInWebApp));
    }
  };

export default postMessageToSwagApp;
